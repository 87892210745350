<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">庆贺！历思物证科技产研体系成员企业——堃晟，通过了“CMA”授权颁发的珠宝玉石及贵金属的能力资格认证！</div>
      <p class="time">2019.07.10</p>
      <p class="space_content" >
        近日，历思物证科技产研体系成员企业——厦门堃晟检测技术有限公司（以下简称“堃晟”）顺利通过了
        <span style="font-weight: 600">
          CMA （中国国家质量检测部门）授权颁发的珠宝玉石及贵金属的能力资格认证。
        </span>
      </p>

      <p class="space_content" >
        堃晟是一家专业的检测机构，目前检测业务范围包括珠宝玉石及贵金属、环境检测、食品检验、微量物证鉴定（案件、事故现场等遗留物鉴定）、法医物证鉴定(DNA亲权鉴定、个体识别）、法医毒物鉴定（酒精、毒品等检测）。拥有国家标准专业实验室，实验室洁净区达到万级标准，配置超快速拉曼成像光谱仪、X荧光光谱仪、傅立叶变换红外光谱仪等高端先进的实验设备。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/25-1.png')">
      </div>

      <p class="space_content" >
        2019年4月22日，评审专家组对堃晟珠宝玉石及贵金属的能力资格认证进行评审，堃晟在人员配置、仪器设备和检测能力等方面均得到评审专家们的认可。评审结束后，堃晟通过了珠宝玉石及贵金属的能力资格认证的评审，并于2019年6月6日颁发证书。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/25-2.png')">
        <p class="center_">（珠宝玉石及贵金属的能力资格认证的现场）</p>
      </div>


      <p class="space_content" style="font-weight: 600">
        那么，这个“CMA认证”到底是什么意思呢？
      </p>
      <p class="space_content" >
        “CMA”（China Inspection Body andLaboratory Mandatory Approval）是中国计量认证的英文缩写。
      </p>
      <p class="space_content" >
        是根据中华人民共和国计量法的规定，由省级以上人民政府计量行政部门对检测机构的检测能力及可靠性进行的一种全面的认证及评价。这种认证对象是所有对社会出具公正数据的产品质量监督检验机构及其它各类实验室；如各种产品质量监督检验站、环境检测站、疾病预防控制中心及其他的检测机构等。
      </p>
      <p class="space_content" >
        因此，所有对社会出具公正数据的产品质量监督检验机构及其它各类实验室必须取得中国计量认证，即CMA认证。只有取得计量认证合格证书的检测机构，才能够从事检测检验工作。
      </p>
      <p class="space_content" >
        取得计量认证合格证书的检测机构，允许其在检验报告上使用CMA标记；有CMA标记的检验报告可用于用于贸易的出证、产品质量评定、司法鉴定结果等，具有法律效力。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/25-3.png')">
        <p class="center_">CMA（中国计量认证）的标志</p>
      </div>

      <p class="space_content" >
        近年来，随着珠宝玉石市场风生水起，市面上珠宝玉石以假乱真、以次充好的现象层出不穷。为确定珠宝类商品的优劣真伪，越来越多的人会找检测机构进行鉴定。随着鉴定的需求日益增加，社会上不断涌现出形形色色的检测机构，鉴定市场呈现一片繁荣景象。但“繁荣”的背后暗藏隐忧，很多检测机构良莠不齐、鱼龙混杂，鉴定的技术和结果的准确性也参差不齐，使有鉴定需求的人很难选择。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/25-4.png')">

      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/25-5.png')">

      </div>
      <p class="space_content" style="font-weight: 600">
        那么，面对五花八门的检测机构，如何选择一家正规权威的检测机构？
      </p>
      <p class="space_content" style="font-weight: 600">
        1、看资质
      </p>
      <p class="space_content" >
        根据以上科普，只有取得计量认证合格证书的检测机构，才能够从事检测检验工作。也就是说，具有“CMA”计量认证的检测机构，才是合法的检验机构。
      </p>
      <p class="space_content" >
        但市面上，有些所谓的珠宝玉石"检测机构"，未经有关部门审批，且未取得计量认证合格证书，也未通过珠宝玉石检测的能力资格认证，其开具的“鉴定证书”及“检测报告”，是不具备没有任何法律效力的。
      </p>
      <p class="space_content" >
        所以，在选择珠宝玉石检测机构时，先确认其是否取得了计量认证合格证书，是否通过了珠宝玉石检测的能力资格认证。如二者皆是，其所开具的检测报告才具有法律效力。
      </p>

      <p class="space_content" style="font-weight: 600">
        2、看设备
      </p>
      <p class="space_content" >
        最初，珠宝行业的鉴定多依靠肉眼观察，凭经验说话。但如今，随着科技的发展，珠宝玉石的造假技术日新月异，招数也屡屡出新。一些传统、常规的珠宝玉石鉴定仪器及鉴定方法越来越难于满足珠宝鉴定的要求。
      </p>
      <p class="space_content" >
        很多珠宝玉石相关的疑难鉴定问题，越来越需要先进的检测设备来解决。通过先进仪器检测分析出珠宝玉石的化学成分或结构特征，再根据检测的数据得出更为客观的鉴定结果，这是目前最为科学的珠宝玉石鉴定方式。
      </p>

      <p class="space_content" style="font-weight: 600">
        3、看能力
      </p>
      <p class="space_content" >
        专业的检测机构需要综合各方面的能力，为保障鉴定结果的科学性及公正性，需要考察其检测工作是否依据国家相关法律法规、产品标准及技术规范。以及机构是否具有标准化的质量管理体系，是否具有规范化的运营模式，在以上的基础上再考量服务水平。
      </p>
      <p class="space_content" >
        总之，在同等条件下应尽可能选择受到有关部门严格监管，在设备、技术、人员各方面管理规范到位的检测机构。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/25-6.png')">
        <p class="center_">X荧光光谱仪</p>
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/25-7.png')">
        <p class="center_">超快速拉曼成像光谱仪</p>
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/25-8.png')">
        <p class="center_">傅立叶变换红外光谱仪</p>
      </div>

      <p class="space_content" >
        （实验室先进的仪器设备）
      </p>
      <p class="space_content" >
        在此醒大家：一定要谨慎选择检测机构，为避免上当受骗和被人忽悠，给自己造成不必要的损失和麻烦，建议大家还是到正规有资质的检测机构进行检测。
      </p>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/24'"><p class="ellipsis1">庆贺，全项7大类一次性通过！根据环境损害鉴定新资质要求，历思顺利进行重新审核登记！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/26'"><p class="ellipsis1">武汉市司法局关太兵局长一行莅临福建历思司法鉴定所调研！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
